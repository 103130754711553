import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import step1 from './step1.png';
import step2 from './step2.png';
import step3 from './step3.png';
import step4 from './step4.png';

const data = [
    {
        text: "Select your luggage",
        image: step1
    },
    {
        text: "Find an empty floor area with good lighting",
        image: step2
    },
    {
        text: "Place the virtual luggage on the floor",
        image: step3
    },
    {
        text: "Compare your luggage to the virtual luggage",
        image: step4
    }
];

class HowItWorksPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {index: 0, touchStarted: false, startX: 0};
    }

    onExitOverlayClick = (e) => {
        this.setState({index: 0});
        this.props.onExitOverlayClick();
    };

    onIndexChanged = (index) => {
        this.setState({index: index});
    };

    onLeftArrowClick = () => {
        let newIndex = this.state.index - 1;
        if(newIndex < 0){
            newIndex = 0;
        }
        this.onIndexChanged(newIndex);
    };

    onRightArrowClick = () => {
        let newIndex = this.state.index + 1;
        if(newIndex === data.length){
            newIndex = data.length - 1;
        }
        this.onIndexChanged(newIndex);
    };

    onTouchStarted = (e) => {
        this.setState({touchStarted: true, startX: e.touches[0].clientX});
    };

    onTouchMove = (e) => {
        if(!this.state.touchStarted){
            return;
        }

        const delta = e.touches[0].clientX - this.state.startX;
        if(delta > 40){
            this.onLeftArrowClick();
            this.onTouchEnded();
        } else if(delta < -40) {
            this.onRightArrowClick();
            this.onTouchEnded();
        }
    };

    onTouchEnded = () => {
        this.setState({touchStarted: false})
    };

    render() {
        const active = this.props.active;

        const items = data.map((el, index) => {
            return (
                <div className={'how-it-works-popup-item'} key={index}>
                    <p className={'how-it-works-popup-item-header'}>- Step {index + 1} -</p>
                    <img src={el.image} alt="" className={'how-it-works-popup-item-image'} />
                    <p className={'how-it-works-popup-item-text'}>{el.text}</p>
                </div>
            )
        });

        return(
            <div>
                {active ?
                    <div className={'openar-warning-popup'}>
                        <div className={'black-overlay'} >
                            <div className={'openar-warning-popup-container'}>
                                <div className={'openar-warning-popup-close-button-container'}>
                                    <div onClick={this.onExitOverlayClick.bind(this)} className={'openar-warning-popup-close-button'} />
                                </div>
                                <div className={'how-it-works-popup-header'}>How it works?</div>
                                <div className={'how-it-works-popup-carousel-body'} onTouchMove={this.onTouchMove.bind(this)} onTouchStart={this.onTouchStarted.bind(this)} onTouchEnd={this.onTouchEnded.bind(this)}>
                                    <div className={'carousel-items'} style={{width: `${100 * data.length}%`, left: `${-this.state.index * 100}%`}}>
                                        {items}
                                    </div>
                                </div>

                                <div className={'how-it-works-popup-buttons'}>
                                    {(this.state.index > 0 && this.state.index < data.length - 1? <div onClick={this.onLeftArrowClick.bind(this)} className={'how-it-works-popup-previous'}>{'< Previous'}</div>: null)}
                                    {(this.state.index === data.length - 1 ? <p onClick={this.onExitOverlayClick.bind(this)} className={'how-it-works-popup-quit'}>{'Start Now'}</p>: null)}
                                    {(this.state.index < data.length - 1 ? <div onClick={this.onRightArrowClick.bind(this)} className={'how-it-works-popup-next'}>{'Next >'}</div>: null)}
                                </div>
                            </div>
                        </div>
                    </div> :
                    null}
            </div>
        )
    }
}

HowItWorksPopUp.propTypes = {
    active: PropTypes.bool.isRequired,
    onExitOverlayClick: PropTypes.func.isRequired
};

export default HowItWorksPopUp;
