import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as Browsers from '../../constants/Browsers';
import './styles.css';

class ARButton extends Component {
    onClick = () => {
        if(this.props.onClick){
            this.props.onClick();
        }
    };

    render() {
        let device = this.props.device;
        let isIOS = false;

        let href = '';
        if(device === Browsers.ANDROID_AR_READY){
            href = 'intent://' + window.location.hostname + this.props.androidLink + '?mode=ar\_only\#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser\_fallback\_url=https://developers.google.com/ar;end;';
        } else if(device === Browsers.SAFARI_AR_READY){
            isIOS = true;
            href = this.props.iosLink + `#callToAction=Upgrade&checkoutTitle=${this.props.baggageName}&checkoutSubtitle=Need%20more%20baggage&price=15$`;
        } else if(device === Browsers.SAFARI_AR_READY_IOS_12){
            isIOS = true;
            href = this.props.ios12Link;
        }

        return(
            <div className={'ar-button-holder'}>
                <a href={href} rel={isIOS ? 'ar' : ''}><img src="#" alt="" style={{display:'none'}}/> <button className={'ar-button'} onClick={this.onClick.bind(this)}>Start now</button></a>
            </div>
        )
    }
}

ARButton.propTypes = {
    device: PropTypes.string.isRequired,
    iosLink: PropTypes.string.isRequired,
    ios12Link: PropTypes.string.isRequired,
    androidLink: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    baggageName: PropTypes.string.isRequired
};

export default ARButton;