import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WelcomeBoard from '../../components/WelcomeBoard';
import './styles.css';
import ExampleBoard from "../../components/ExampleBoard";
import UpgradeBoard from "../../components/UpgradeBoard";
import BaggageBoard from "../../components/BaggageBoard";
import OpenARWarningPopUp from "../../components/OpenARWarningPopUp";
import AirlineWebsiteBoard from "../../components/AirlineWebsiteBoard";
import HowItWorksPopUp from "../../components/HowItWorksPopUp";

class Content extends Component{
    constructor(props){
        super(props);
        this.state = {openARPopupActive: false, data: {}, howItWorksPopUpActive: false};
    }

    componentDidMount() {
        setTimeout(() => this.setState({howItWorksPopUpActive: true}), 1000);
    }

    onMeasureButtonClick = () => {
        this.setState({openARPopupActive: true});
    };

    onStartButtonClick = () => {
        this.setState({openARPopupActive: false});
    };

    onBlackOverlayButtonClick = () => {
        this.setState({openARPopupActive: false});
    };

    onBaggageIndexChanged = (data) => {
        this.setState({data: data});
    };

    openHowItWorksPopUp = () => {
        this.setState({howItWorksPopUpActive: true});
    };

    onHowItWorksExitButtonClick = () => {
        this.setState({howItWorksPopUpActive: false});
    };

    render() {
        const {openARPopupActive} = this.state;
        const {data} = this.state;
        const browser = this.props.browser;
        const {howItWorksPopUpActive} = this.state;

        return(
            <div className={'content'}>
                <WelcomeBoard />
                <ExampleBoard onMeasureButtonClick={this.onMeasureButtonClick.bind(this)} onHowItWorksButtonClick={this.openHowItWorksPopUp.bind(this)}/>
                <BaggageBoard onBaggageIndexChange={this.onBaggageIndexChanged.bind(this)}/>
                <UpgradeBoard />
                <AirlineWebsiteBoard />
                <OpenARWarningPopUp
                    active={openARPopupActive}
                    onButtonClick={this.onStartButtonClick.bind(this)}
                    onBlackOverlayClick={this.onBlackOverlayButtonClick.bind(this)}
                    device={browser}
                    data={data}
                />
                <HowItWorksPopUp active={howItWorksPopUpActive} onExitOverlayClick={this.onHowItWorksExitButtonClick.bind(this)} />
            </div>
        )
    }
}

Content.propTypes = {
    browser: PropTypes.string.isRequired
};

export default Content;