import React, {Component} from 'react';
import './styles.css';
import PropTypes from 'prop-types';

class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {index: 1, touchStarted: false, startX: 0};
    }

    onNameButtonClick = (index) => {
        this.setState({index: index});
        this.props.onIndexChanged(index);
    };

    onLeftArrowClick = () => {
        let newIndex = this.state.index - 1;
        if(newIndex < 0){
            newIndex = 0;
        }
        this.onNameButtonClick(newIndex);
    };

    onRightArrowClick = () => {
        let newIndex = this.state.index + 1;
        if(newIndex === this.props.namesList.length){
            newIndex = this.props.namesList.length - 1;
        }
        this.onNameButtonClick(newIndex);
    };

    onTouchStarted = (e) => {
        this.setState({touchStarted: true, startX: e.touches[0].clientX});
    };

    onTouchMove = (e) => {
        if(!this.state.touchStarted){
            return;
        }

        const delta = e.touches[0].clientX - this.state.startX;
        if(delta > 40){
            this.onLeftArrowClick();
            this.onTouchEnded();
        } else if(delta < -40) {
            this.onRightArrowClick();
            this.onTouchEnded();
        }
    };

    onTouchEnded = () => {
        this.setState({touchStarted: false})
    };

    render() {
        const namesWidth = 100 / this.props.namesList.length;
        const names = this.props.namesList.map((el, index) => {
            return (
                <div
                    key={index}
                    className={`carousel-name-item ${this.state.index === index ? ' active' : ''}`}
                    style={{width: `${namesWidth}%`}}
                    onClick={this.onNameButtonClick.bind(this, index)}
                >
                    {el}
                </div>
            )
        });

        const currentIndex = this.state.index;

        return (
            <div className={'carousel-container'}>
                <div className={'carousel-names-container'}>
                    {names}
                </div>
                <div className={'carousel-body no-background'} onTouchMove={this.onTouchMove.bind(this)} onTouchStart={this.onTouchStarted.bind(this)} onTouchEnd={this.onTouchEnded.bind(this)}>
                    <div className={'carousel-items'} style={{width: `${100 * this.props.itemsList.length}%`, left: `${-currentIndex * 100}%`}}>
                        {this.props.itemsList}
                    </div>
                    <div className={'carousel-left-arrow'} onClick={this.onLeftArrowClick.bind(this)}>
                    </div>
                    <div className={'carousel-right-arrow'} onClick={this.onRightArrowClick.bind(this)}>
                    </div>
                </div>
            </div>
        )
    }
}

Carousel.propTypes = {
    onIndexChanged: PropTypes.func.isRequired,
    itemsList: PropTypes.array.isRequired,
    namesList: PropTypes.array.isRequired
};

export default Carousel;