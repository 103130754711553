import React from 'react'
import './styles.css';

const AirlinesLogo = ({airlinesName, logo}) => {
    return(
        <div className={'airlines-logo-container'}>
            <img alt={'logo'} src={logo} className={'airlines-logo'}/>
            <p className={'airlines-name'}>{airlinesName}</p>
        </div>
    )
};

export default AirlinesLogo;