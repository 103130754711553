import React, {Component} from 'react';
import data from '../../constants/BaggageData';
import BaggageContainer from "../BaggageContainer";
import './styles.css';
import Carousel from "../Carousel";
import PropTypes from 'prop-types';

class BaggageBoard extends Component {
    constructor(props){
        super(props);
        this.state = {active: 1};
    }

    componentDidMount() {
        this.onIndexChanged(1);
    }

    onIndexChanged = (index) => {
        if(index < data.length){
            this.props.onBaggageIndexChange(data[index]);
        }
    };

    render() {
        let names = [];
        let baggageList = data.map((el, index) => {
            names.push(el.name);
            return <BaggageContainer baggageData={el} key={index}/>
        });

        return(
            <div className={'baggage-board-container'}>
                <Carousel onIndexChanged={this.onIndexChanged.bind(this)} itemsList={baggageList} namesList={names} />
            </div>
        )
    }
}

BaggageBoard.propTypes = {
  onBaggageIndexChange: PropTypes.func.isRequired
};

export default BaggageBoard;