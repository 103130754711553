import React, {Component} from 'react';
import './styles.css';
import logo from './logo.png';
import AirlinesLogo from "../../components/AirlinesLogo";
import CarryAirLogo from "../../components/CarryAirLogo";
import {connect} from "react-redux";

class Header extends Component{
    render() {
        const {airlinesName} = this.props.user;
        const {airlinesLogo} = this.props.user;

        return(
            <header className={'header'}>
                <div className={'header-container'}>
                    <AirlinesLogo airlinesName={airlinesName} logo={airlinesLogo}/>
                    <CarryAirLogo poweredBy={true} logo={logo} big={false}/>

                    <ul className={'header-nav'}>
                        <li><a href={'#'}>Travel Light</a></li>
                        <li><a href={'#'}>Economy Class</a></li>
                        <li><a href={'#'}>Business Class</a></li>
                    </ul>
                </div>
            </header>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(Header)
