import * as Browsers from '../../constants/Browsers';

function BrowserSupport() {
    let desktopPlatforms = ["Win", "Mac", "X11", "Linux"];

    function isDesktopBrowser() {
        for (let os of desktopPlatforms){
            if (navigator.userAgent.indexOf(os) !== -1){
                return true;
            }
        }

        return false;
    }

    function isAndroidBrowser() {
        return navigator.userAgent.match(/Android/i);
    }
    
    function isIOSBrowser() {
        return navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
    }

    function isChromeBrowser() {

    }

    function isAREnabledForIOS(){
        const a = document.createElement("a");
        return a.relList.supports("ar");
    }

    function getIOSVersion(){
        let uaindex = navigator.userAgent.indexOf( 'OS ' );
        if(uaindex > -1){
            let version =  navigator.userAgent.substr( uaindex + 3, 2 );
            return parseInt(version);
        }

        return -1;
    }
    
    return{
        getBrowserSupport: function(){
            if(isAndroidBrowser()){
                return Browsers.ANDROID_AR_READY;
            } else if(isIOSBrowser()){
                let iosVersion = getIOSVersion();
                if(iosVersion < 12){
                    return Browsers.SAFARI_AR_READY;
                } else if(iosVersion === 12){
                    if(isAREnabledForIOS()){
                        return Browsers.SAFARI_AR_READY_IOS_12;
                    } else {
                        return Browsers.SAFARI_AR_NOT_READY;
                    }
                } else {
                    if(isAREnabledForIOS()){
                        return Browsers.SAFARI_AR_READY;
                    } else {
                        return Browsers.SAFARI_AR_NOT_READY;
                    }
                }
            }

            if(isDesktopBrowser()){
                return Browsers.DESKTOP_BROWSER;
            }

            return Browsers.OTHER_DEVICE;
        }
    }
}

export default BrowserSupport();