import React from 'react';
import './styles.css';

const UpgradeBoard = () => {
    return(
        <div className={'upgrade-board-container'}>
            <p className={'upgrade-board-text'}>Need a bigger bag?</p>
            <button className={'upgrade-board-button'}>Upgrade</button>
        </div>
    )
};

export default UpgradeBoard;