import airlineLogo from './airLineLogo.png';

let initialState = {
    username: 'Passengers Name',
    flightClass: 'Economy',
    departureFromCity: 'DEL',
    departureFromTime: '11:30',
    nonStop: true,
    departureToCity: 'BOM',
    departureToTime: '13:50',
    time: '2 hrs 20 min',
    baggageWeight: '18kg',
    baggageSize: '56 x 36 x 23cm',
    airlinesName: 'Airlines',
    airlinesLogo: airlineLogo,
    airlineWebsiteLink: 'https://www.airlines.com'
};

export default function page(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}