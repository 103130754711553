import React, {Component} from 'react';
import './styles.css';
import {connect} from "react-redux";
import path from './path.png';

class TicketTopPart extends Component{
    render() {
        const {username} = this.props.user;
        const {flightClass} = this.props.user;
        const {departureFromCity} = this.props.user;
        const {departureFromTime} = this.props.user;
        const {nonStop} = this.props.user;
        const {departureToCity} = this.props.user;
        const {departureToTime} = this.props.user;
        const {time} = this.props.user;

        return (
            <div className={'ticket-top-part-container'}>
                <div className={'ticket-top-part-row'}>
                    <p className={'ticket-top-part-passengers-name'}>{username}</p>
                    <p className={'ticket-top-part-class-name'}>{flightClass}</p>
                </div>
                <div className={'ticket-top-part-row'}>
                    <div>
                        <p className={'ticket-top-part-departure-city'}>{departureFromCity}</p>
                        <p className={'ticket-top-part-departure-time'}>{departureFromTime}</p>
                    </div>
                    <div>
                        <div className={'ticket-top-part-row-no-padding'}>
                            <img src={path} alt={'plane'} className={'ticket-top-plane-path'}/>
                        </div>
                        <div className={'ticket-top-part-row-no-padding'}>
                            <p className={'ticket-top-part-plane-path-text'}>{nonStop ? 'Non-Stop' : 'Transfer'}</p>
                            <p className={'ticket-top-part-plane-path-text'}>{time}</p>
                        </div>
                    </div>
                    <div className={'ticket-top-part-column-last'}>
                        <p className={'ticket-top-part-departure-city'}>{departureToCity}</p>
                        <p className={'ticket-top-part-departure-time'}>{departureToTime}</p>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(TicketTopPart)
