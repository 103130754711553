import React from 'react'
import './styles.css';

const CarryAirLogo = ({poweredBy, logo, big}) => {
    return(
        <div className={'carryair-logo-container'}>
            <p className={poweredBy ? 'carryair-powered-by-text' : 'carryair-powered-by-text-hide'}>Powered By</p>
            <img className={big ? 'carryair-logo-big' : 'carryair-logo-small'} alt={'logo'} src={logo} />
            <p className={big ? 'carryair-product-name-big' : 'carryair-product-name-small'}>Carry<span>AIR</span></p>
        </div>
    )
};

export default CarryAirLogo;