import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import logo from "../../containers/Header/logo.png";
import ARButton from "../ARButton";
import * as Browsers from "../../constants/Browsers";
import CarryAirLogo from "../CarryAirLogo";
import lamp from './lamp.png';
import baggage from './baggage.png';

class OpenARWarningPopUp extends Component {
    onBlackOverlayClick = (e) => {
        this.props.onBlackOverlayClick();
    };

    getHeaderText = () => {
        const device = this.props.device;
        if(device === Browsers.SAFARI_AR_READY_IOS_12 ||
            device === Browsers.SAFARI_AR_READY ||
            device === Browsers.ANDROID_AR_READY){
            return "Please note:"
        } else {
            return "Warning!"
        }
    };

    getPopUpText = () => {
        const device = this.props.device;
        if(device === Browsers.SAFARI_AR_READY_IOS_12 ||
            device === Browsers.SAFARI_AR_READY ||
            device === Browsers.ANDROID_AR_READY){
            return <ul className={'openar-warning-popup-normal-text'}>
                    <li>Select an area with <span>good lighting</span></li>
                    <li>Best to try on <span>non-reflective floor surface</span></li>
                </ul>
        } else if (device === Browsers.IOS_VERSION_BELOW_12){
            return <p className={'openar-warning-popup-normal-text'}>Hey, we've detected that your phone runs under IOS below 12.</p>;
        } else if(device === Browsers.SAFARI_AR_NOT_READY){
            return <p className={'openar-warning-popup-normal-text'}>Hey, we've detected that you're trying to launch the app in browser that can't run AR.</p>;
        } else if(device === Browsers.DESKTOP_BROWSER){
            return <p className={'openar-warning-popup-normal-text'}>Hey, we've detected that you're trying to launch app on the desktop.</p>;
        } else {
            return <p className={'openar-warning-popup-normal-text'}>Hey, you're trying to launch the app on unknown device.</p>;
        }
    };

    getPopUpTextWithBackground = () => {
        const device = this.props.device;
        if(device === Browsers.SAFARI_AR_READY_IOS_12 ||
            device === Browsers.SAFARI_AR_READY ||
            device === Browsers.ANDROID_AR_READY){
            return <p className={'openar-warning-popup-normal-text'}>For accurate measurement keep the bag at <span>100% ratio</span></p>
        } else if (device === Browsers.IOS_VERSION_BELOW_12){
            return <p className={'openar-warning-popup-normal-text'}>Please, update your phone to launch the app.</p>;
        } else if(device === Browsers.SAFARI_AR_NOT_READY){
            return <p className={'openar-warning-popup-normal-text'}>Please, launch the app in Safari browser.</p>;
        } else if(device === Browsers.DESKTOP_BROWSER){
            return <p className={'openar-warning-popup-normal-text'}>Please, launch the app on the mobile device that runs on IOS or Android.</p>;
        } else {
            return <p className={'openar-warning-popup-normal-text'}>Please, launch the app on the mobile device that runs on IOS or Android.</p>;
        }
    };

    getPopUpButton = () => {
        const device = this.props.device;
        const data = this.props.data;

        if(device === Browsers.SAFARI_AR_READY_IOS_12 ||
            device === Browsers.SAFARI_AR_READY ||
            device === Browsers.ANDROID_AR_READY){
            return <ARButton device={device} iosLink={data.iosLink} androidLink={data.androidLink} ios12Link={data.ios12Link} onClick={this.props.onButtonClick} baggageName={data.name}/>;
        } else {
            return <div className={'ar-button-holder'}>
                        <button className={'ar-button'} onClick={this.props.onButtonClick}>Ok</button>
                    </div>
        }
    };

    render() {
        const active = this.props.active;

        const header = this.getHeaderText();
        const popUpText = this.getPopUpText();
        const popUpTextWithBackground = this.getPopUpTextWithBackground();
        const popUpButton = this.getPopUpButton();

        return(
            <div>
                {active ?
                    <div className={'openar-warning-popup'}>
                        <div className={'black-overlay'} >
                            <div className={'openar-warning-popup-container'}>
                                <div className={'openar-warning-popup-close-button-container'}>
                                    <div onClick={this.onBlackOverlayClick.bind(this)} className={'openar-warning-popup-close-button'} />
                                </div>
                                 <div className={'openar-warning-popup-header'}>
                                    <CarryAirLogo logo={logo} poweredBy={false} big={false} />
                                </div>

                                <p className={'openar-warning-popup-header-text'}>{header}</p>
                                <div className={'openar-warning-popup-text-with-background top'}>
                                    <div className={'openar-warning-popup-image top'}>
                                        <img src={lamp} alt=""/>
                                    </div>
                                    {popUpText}
                                </div>
                                <div className={'openar-warning-popup-text-with-background bottom'}>
                                    <div className={'openar-warning-popup-image bottom'}>
                                        <img src={baggage} alt=""/>
                                    </div>
                                    {popUpTextWithBackground}
                                </div>
                                {popUpButton}
                            </div>
                        </div>
                    </div> :
                    null}
            </div>
        )
    }
}

OpenARWarningPopUp.propTypes = {
    active: PropTypes.bool.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    onBlackOverlayClick: PropTypes.func.isRequired,
    device: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
};

export default OpenARWarningPopUp;
