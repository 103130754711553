import checked from './baggageImages/checked.png';
import carryon from './baggageImages/carryOn.png';
import personal from './baggageImages/personal.png';

import businessIOS from './baggageModel/CarryOn_LargeLaggage_Arrows_iOS13.usdz';
import businessIOS12 from './baggageModel/CarryOn_LargeLaggage_Arrows_iOS12Fix.usdz';
import businessAndroid from './baggageModel/CarryOn_LargeLaggage_Arrows.glb';

import economyIOS from './baggageModel/CarryOn_HandLaggage_Arrows_iOS13.usdz';
import economyIOS12 from './baggageModel/CarryOn_HandLaggage_Arrows_iOS12Fix.usdz';
import economyAndroid from './baggageModel/CarryOn_HandLaggage_Arrows.glb';

import personalIOS from './baggageModel/CarryOn_Backpack_Arrows_iOS13.usdz';
import personalIOS12 from './baggageModel/CarryOn_Backpack_Arrows_iOS12Fix.usdz';
import personalAndroid from './baggageModel/CarryOn_Backpack_Arrows.glb';


let data = [
    {
        class: "Business",
        name: "Checked",
        weight: "23kg",
        iosLink: businessIOS,
        ios12Link: businessIOS12,
        androidLink: businessAndroid,
        image: checked
    },
    {
        class: "Economy",
        name: "Carry-on",
        weight: "18kg",
        iosLink: economyIOS,
        ios12Link: economyIOS12,
        androidLink: economyAndroid,
        image: carryon
    },
    {
        class: "Economy",
        name: "Personal",
        weight: "8kg",
        iosLink: personalIOS,
        ios12Link: personalIOS12,
        androidLink: personalAndroid,
        image: personal
    }
];

export default data;