import React, {Component} from 'react';
import './styles.css';
import {connect} from "react-redux";
import weightIcon from './wightIcon.png';

class TicketBottomPart extends Component{
    render() {
        const {baggageWeight} = this.props.user;
        const {baggageSize} = this.props.user;

        return (
            <div className={'ticket-bottom-part-container'}>
                <div className={'ticket-bottom-part-row'}>
                    <img src={weightIcon} alt="icon" className={'ticket-bottom-part-icon'}/>
                    <p className={'ticket-bottom-part-weight'}>{baggageWeight}</p>
                    <p className={'ticket-bottom-part-size'}>| {baggageSize}</p>
                </div>
                <div>
                    <button className={'ticket-bottom-part-upgrade-button'}>Upgrade</button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(TicketBottomPart)
