import React, {Component} from 'react';
import './styles.css';
import TicketBottomPart from "../TicketBottomPart";
import PropTypes from 'prop-types';
import howItWorks from './howItWorks.png';

class ExampleBoard extends Component{
    onMeasureButtonClick = () => {
        this.props.onMeasureButtonClick();
    };

    onHowItWorksButtonClick = () => {
        this.props.onHowItWorksButtonClick();
    };

    render() {
        return(
            <div className={'example-board-container'}>
                <TicketBottomPart />
                
                <div className={'example-board-main-section'}>
                    <p className={'example-board-header'}>Guarantee your luggage is the right size</p>
                    <p className={'example-board-small-text'}>Measure using augmented reality</p>
                    <div className={'example-board-button-section'}>
                        <button className={'example-board-measure-button'} onClick={this.onMeasureButtonClick.bind(this)}>Measure my bag</button>
                        <img src={howItWorks} className={'example-board-how-it-works-button'} onClick={this.onHowItWorksButtonClick.bind(this)}/>
                    </div>
                </div>
            </div>
        )
    }
}

ExampleBoard.propTypes = {
  onMeasureButtonClick: PropTypes.func.isRequired,
  onHowItWorksButtonClick: PropTypes.func.isRequired
};

export default ExampleBoard;