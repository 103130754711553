import React from 'react';
import './styles.css'
import TicketTopPart from "../TicketTopPart";

const WelcomeBoard = () => {
    return(
        <div className={'welcome-board'}>
            <div className={'welcome-board-container'}>
                <p className={'welcome-aboard-text'}>Welcome aboard</p>
                <TicketTopPart />
            </div>
        </div>
    )
};


export default WelcomeBoard;