import React, {Component} from 'react';
import logo from './logo.png';
import './styles.css';
import CarryAirLogo from "../../components/CarryAirLogo";

class Footer extends Component{
    constructor(props) {
        super(props);
        this.state = { width: 0};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth});
    }

    render() {
        return(
            <footer className={'footer'}>
                <div className={'footer-container'}>
                    <CarryAirLogo big={false} logo={logo} poweredBy={true} />

                    <div className={'footer-nav-container'}>
                        <ul className={'footer-nav'}>
                            <li><a href={'#'}>Travel Light</a></li>
                            <li><a href={'#'}>Economy Class</a></li>
                            <li><a href={'#'}>Business Class</a></li>
                        </ul>
                    </div>
                </div>
                <p className={'footer-company-name'}>Created by <a href={'#'} className={'footer-company-link'}>Spartans AI</a></p>
            </footer>
        )
    }
}

export default Footer;