import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import weightIcon from './wightIcon.png';

class BaggageContainer extends Component {
    render() {
        let baggageData = this.props.baggageData;

        return(
            <div className={'baggage-container'}>
                <div className={'baggage-image'}>
                    <img src={baggageData.image} alt=""/>
                </div>

                <div className={'baggage-info'}>
                    <p className={'baggage-class'}>{baggageData.class}</p>
                    <img src={weightIcon} alt=""/>
                    <p className={'baggage-weight'}>{baggageData.weight}</p>
                </div>
            </div>
        )
    }
}

BaggageContainer.propTypes = {
    baggageData: PropTypes.object.isRequired,
};

export default BaggageContainer;