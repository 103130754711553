import React, {Component} from 'react';
import './styles.css';
import AirlinesLogo from "../AirlinesLogo";
import {connect} from "react-redux";

class AirlineWebsiteBoard extends Component {
    render() {
        const {airlinesName} = this.props.user;
        const {airlinesLogo} = this.props.user;
        const {airlineWebsiteLink} = this.props.user;

        return(
            <div className={'airlinewebsite-board-container'}>
                <AirlinesLogo airlinesName={airlinesName} logo={airlinesLogo}/>
                <a href={airlineWebsiteLink} className={'airlinewebsite-board-button'}>Back To Website</a>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(AirlineWebsiteBoard)