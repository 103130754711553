import React, {Component} from 'react';
import BrowserSupport from '../../helpers/BrowserSupport'
import Header from "../Header";
import Footer from "../Footer";
import Content from '../Content';

class App extends Component {
    constructor(props){
        super(props);
        this.state = {browser: ""};
    }

  componentDidMount() {
    this.setState({browser: BrowserSupport.getBrowserSupport()});
  }

  render() {
    return(
      <div >
        <Header />
        <Content browser={this.state.browser} />
        <Footer />
      </div>
    )
  }
}

export default App;
